<template>
  <div class="page">
    <div class="result">
      <template v-if="status">
        <img src="@/assets/pay-success.png" alt="" srcset="" />
        <h2>{{ status ? "Thanh toán thành công" : "Thanh toán thất bại" }}</h2>
        <div class="order">
          <div class="order-name">Mã đơn</div>
          <div class="order-id">{{ orderId }}</div>
        </div>
        <div class="order pay" v-show="amount">
          <div class="order-name">Số tiền thanh toán</div>
          <div class="order-id">{{ amount }}</div>
        </div>
        <div class="order" v-show="free_chance">
          <div class="order-name">Miễn phí đơn hạn giờ</div>
          <div class="order-id">{{ free_chance }}</div>
        </div>
      </template>
      <template v-else>
        <img src="@/assets/pay-fail.png" alt="" srcset="" />
        <h2>{{ status ? "Thanh toán thành công" : "Thanh toán thất bại" }}</h2>
      </template>
      <!-- <el-button
        type="primary"
        style="width: 200px; border-radius: 20px; margin-top: 40px"
        @click="close"
      >
        Quay lại
      </el-button> -->
    </div>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      status: 0,
      orderId: "",
      amount: "",
      free_chance: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getRouteData();
  },
  methods: {
    getRouteData() {
      if (this.$route.query.status) {
        // this.status = Number(this.$route.query.status);
        Number(this.$route.query.status) === 1
          ? (this.status = 1)
          : (this.status = 0);
        this.orderId = this.$route.query.orderId;
        if (this.$route.query.status === "SUCCESS") {
          this.status = 1;
          this.orderId = this.$route.query.outTradeNo;
        }
      } else if (this.$route.query.param) {
        const params = new URLSearchParams(
          Base64.decode(this.$route.query.param)
        );
        const obj = {};
        for (const [key, value] of params) {
          obj[key] = decodeURIComponent(value);
        }
        // this.status = obj.status;
        Number(obj.status) === 1 ? (this.status = 1) : (this.status = 0);
        this.orderId = obj.mch_order_id;
        this.amount = obj.amount;
      } else if (this.$route.query.vnp_ResponseCode) {
        Number(this.$route.query.vnp_ResponseCode) === 0
          ? Number((this.status = 1))
          : Number((this.status = 0));
        this.orderId = this.$route.query.vnp_TransactionNo;
        this.amount = this.$route.query.vnp_Amount;
      } else if (Number(this.$route.query.resultCode) === 0) {
        Number(this.$route.query.resultCode) === 0
          ? Number((this.status = 1))
          : Number((this.status = 0));
        this.orderId = this.$route.query.orderId;
        this.amount = this.$route.query.amount;
      } else {
        this.status = 0;
      }
      if (params.game_id === "121") {
        window.fbq("track", "ViewContent");
      }
      if (this.orderId) {
        this.getFreeChance();
      }
    },
    //获取免单金额
    getFreeChance() {
      let params = {
        order_no: this.orderId,
      };
      this.$http.post("/apispay/pay/orderInfo", params).then((res) => {
        this.free_chance = res.data.data.free_coin;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  height: 100vh;
  h2 {
    margin: 48px 0;
  }
  .result {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }
  .order {
    display: flex;
    width: 300px;
    justify-content: space-between;
    margin: 10px 0;
    .order-name {
      color: gray;
    }
    .order-id {
      width: 200px;
      text-align: right;
      word-wrap: break-word;
    }
  }
}
</style>
